import '../../../theme/datepicker.css'

import styled from '@emotion/styled'
import { Select } from 'app/components/Common/Select'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { de, enUS, it } from 'date-fns/esm/locale'
import { rgba } from 'emotion-rgba'
import React, { memo, useEffect, useRef, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import NumericInput from 'react-numeric-input'

registerLocale('de', de)
registerLocale('en', enUS)
registerLocale('it', it)

export interface Props {
  IBEID?: number
  languageCode: string
  languagePrefix: string
}

export const Syncrobox = memo(function Syncrobox({
  IBEID,
  languageCode,
  languagePrefix,
}: Props) {
  const [linkerParam, setlinkerParam] = useState()

  const [startDate, setStartDate] = useState<any>()
  const [endDate, setEndDate] = useState<Date>()

  const guestsWrap = useRef(null)
  const [guestsWrapStatus, setGuestsWrapStatus] = useState(false)

  const [guestsTotal, setGuestsTotal] = useState(0)
  const [adultsValue, setAdultsValue] = useState(2)
  const [child614Value, setChild614Value] = useState(0)
  const [child25Value, setChild25Value] = useState(0)
  const [baby01Value, setBaby01Value] = useState(0)

  const adultsLabel = useVocabularyData('adults', languageCode)
  const child614Label = useVocabularyData('child614', languageCode)
  const child25Label = useVocabularyData('child25', languageCode)
  const baby01Label = useVocabularyData('baby01', languageCode)

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (guestsWrap.current && !guestsWrap.current.contains(e.target)) {
      setGuestsWrapStatus(false)
    } else {
      if (guestsWrapStatus && e.target.classList.contains('guests-button')) {
        setGuestsWrapStatus(false)
      } else {
        setGuestsWrapStatus(true)
      }
    }
  }

  useEffect(() => {
    setGuestsTotal(adultsValue + child614Value + child25Value + baby01Value)

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [guestsWrapStatus, adultsValue, child614Value, child25Value, baby01Value])

  return (
    <Container>
      <Form
        action="https://be.bookingexpert.it/book/simple/step1"
        target="_blank"
      >
        <input type="hidden" name="domain" value={IBEID} />
        <input type="hidden" name="lang" value={languagePrefix} />

        <Field>
          <Select
            options={[
              {
                value: '',
                label: useVocabularyData('destination', languageCode),
                hidden: true,
              },
              { value: '41478', label: 'Alagna Mountain Resort & Spa' },
              { value: '40640', label: 'Acaya Golf Resort & Spa' },
              { value: '44263', label: 'Borgo di Luce I Monasteri' },
              { value: '44681', label: 'Riva Toscana Golf Resort & Spa' },
            ]}
            name="hotel"
            variant="ibe"
          />
        </Field>
        <Field className="picker-field">
          <DatePicker
            autoComplete="off"
            dateFormat="dd/MM/yyyy"
            disabledKeyboardNavigation={true}
            locale={languagePrefix || 'it'}
            onChange={(date) => {
              if (date) {
                setStartDate(date)
                setEndDate(undefined)
              }
            }}
            onFocus={(e) => (e.target.readOnly = true)}
            placeholderText={useVocabularyData('arrival', languageCode)}
            minDate={new Date()}
            name="checkin"
            selected={startDate}
          />
        </Field>
        <Field className="picker-field">
          <DatePicker
            autoComplete="off"
            dateFormat="dd/MM/yyyy"
            disabledKeyboardNavigation={true}
            locale={languagePrefix || 'it'}
            onChange={(date) => {
              if (date) {
                setEndDate(date)
              }
            }}
            onFocus={(e) => (e.target.readOnly = true)}
            placeholderText={useVocabularyData('departure', languageCode)}
            minDate={new Date(startDate?.getTime() + 1 * 24 * 60 * 60 * 1000)}
            name="checkout"
            selected={endDate}
          />
        </Field>
        <Guests ref={guestsWrap}>
          <Field className="guests-field">
            <Label>{useVocabularyData('guests', languageCode)}</Label>
          </Field>
          <GuestsWrap
            className={guestsWrapStatus ? 'open' : undefined}
            row
            space="between"
            wrap
          >
            <Field>
              <GuestLabel>{adultsLabel}</GuestLabel>
              <NumericInput
                name="guesttypes[0][16569]"
                min={1}
                max={5}
                style={false}
                value={adultsValue}
                onChange={(num: any) => {
                  setAdultsValue(num)
                }}
              />
            </Field>
            <Field>
              <GuestLabel>{child614Label}</GuestLabel>
              <NumericInput
                name="guesttypes[0][16570]"
                min={0}
                max={5}
                style={false}
                value={child614Value}
                onChange={(num: any) => {
                  setChild614Value(num)
                }}
              />
            </Field>
            <Field>
              <GuestLabel>{child25Label}</GuestLabel>
              <NumericInput
                name="guesttypes[0][16572]"
                min={0}
                max={5}
                style={false}
                value={child25Value}
                onChange={(num: any) => {
                  setChild25Value(num)
                }}
              />
            </Field>
            <Field>
              <GuestLabel>{baby01Label}</GuestLabel>
              <NumericInput
                name="guesttypes[0][16571]"
                min={0}
                max={5}
                style={false}
                value={baby01Value}
                onChange={(num: any) => {
                  setBaby01Value(num)
                }}
              />
            </Field>
          </GuestsWrap>
        </Guests>
        <Submit
          disabled={startDate && endDate ? false : true}
          type="submit"
          name="search"
          value={useVocabularyData('book', languageCode)}
        />
      </Form>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  position: absolute;
  top: calc(100vh - 6.875rem);
  left: 0;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Form = styled.form`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: 3.75rem;

  .react-datepicker {
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    background-color: ${({ theme }) => theme.colors.variants.primaryLight2};
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
  }

  .react-datepicker__current-month {
    font-family: ${({ theme }) => theme.fontFamily.heading};
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day:hover,
  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.variants.secondaryLight};
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
  }

  .react-datepicker__day:focus {
    background-color: ${({ theme }) =>
      theme.colors.variants.secondaryLight} !important;
    color: ${({ theme }) => theme.colors.variants.neutralLight4} !important;
  }

  .react-datepicker__day--today {
    &:after {
      background: ${({ theme }) => theme.colors.variants.secondaryLight};
    }
  }

  .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.colors.variants.neutralLight4} !important;
    opacity: 0.3;
  }

  .react-datepicker-wrapper {
    input {
      height: 3.75rem;
      background-image: url('/chevrons-down.svg');
      background-position-x: calc(100% - 1.25rem);
      color: ${({ theme }) => theme.colors.variants.neutralLight4};
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      font-size: 0.9375rem;
      line-height: 1.4375rem;
      padding: 0 1.25rem;

      &::-webkit-input-placeholder {
        color: ${({ theme }) => theme.colors.variants.neutralLight4};
        opacity: 1;
      }
      &::-moz-placeholder {
        color: ${({ theme }) => theme.colors.variants.neutralLight4};
        opacity: 1;
      }
      &::-ms-input-placeholder {
        color: ${({ theme }) => theme.colors.variants.neutralLight4};
        opacity: 1;
      }
      &::placeholder {
        color: ${({ theme }) => theme.colors.variants.neutralLight4};
        opacity: 1;
      }
    }
  }

  .react-numeric-input {
    display: flex;
    align-items: center;
    margin-left: auto;
    position: relative;
    input {
      width: 2.25rem;
      caret-color: transparent;
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1.5rem;
      margin-left: auto;
      pointer-events: none;
    }
    b {
      width: 1.75rem;
      height: 1.75rem;
      border: 0.125rem solid ${rgba(theme.colors.variants.neutralLight4, 0.2)};
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      text-align: center;
      transition: 0.1s ease-out;
      &:hover {
        border-color: ${({ theme }) => theme.colors.variants.neutralLight4};
      }
      &:before,
      &:after {
        content: '';
        background: ${({ theme }) => theme.colors.variants.neutralLight4};
        position: absolute;
      }
      &:nth-of-type(1) {
        &:before {
          width: 0.75rem;
          height: 0.125rem;
          top: 0.6875rem;
          left: 0.375rem;
        }
        &:after {
          width: 0.125rem;
          height: 0.75rem;
          top: 0.375rem;
          left: 0.6875rem;
        }
      }
      &:last-of-type {
        margin-left: 0.375rem;
        &:before {
          width: 0.75rem;
          height: 0.125rem;
          top: 0.6875rem;
          left: 0.375rem;
        }
      }
    }
  }
`

const Field = styled.div`
  border-bottom: 0.0625rem solid
    ${rgba(theme.colors.variants.neutralLight4, 0.3)};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin-right: 1.5rem;
  position: relative;
  &.picker-field {
    min-width: 12.5rem;
  }
  &.guests-field {
    min-width: 9rem;
  }

  @media (max-width: 1150px) {
    margin-right: 0.4rem;
  }
`

const Guests = styled.div`
  position: relative;
`

const Label = styled.div`
  height: 3.75rem;
  background: url('/chevrons-down.svg') no-repeat calc(100% - 1.25rem);
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 3.75rem;
  padding: 0 1.25rem;
`

const GuestsWrap = styled(FlexBox)`
  min-width: 18.75rem;
  background: ${({ theme }) => theme.colors.variants.primaryLight2};
  opacity: 0;
  padding-bottom: 1.5rem;
  position: absolute;
  bottom: calc(100% + 1rem);
  left: 0;
  text-align: left;
  visibility: hidden;
  z-index: -1;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 3;
  }
  > div {
    display: flex;
    align-items: center;
    width: 100%;
    border: 0;
    margin: 1.5rem 0 0;
    padding: 0 1.5rem;
  }
`

const GuestLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  line-height: 1.25rem;
`

const Submit = styled.input`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.secondaryLight};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-size: 0.8125rem;
  font-weight: 500;
  letter-spacing: 0.0406rem;
  line-height: 1.25rem;
  padding: 0 2.75rem;
  text-align: center;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.secondaryDark};
  }
`
